import React, { Component } from "react";
import { base } from "../../base";

export default class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id_signature,
      photoType: this.props.match.params.visuel,
      name: "",
      lastName: "",
      email: "",
      numbers: [],
      poste: "",
      photo: ""
    };

    const data = base.collection("emails").doc(`${this.state.id}`);

    data.get().then(doc => {
      const fullData = doc.data();
      const stateCopy = { ...this.state };

      stateCopy.name = fullData.name;
      stateCopy.lastName = fullData.lastName;
      stateCopy.numbers = fullData.numbers;
      stateCopy.email = fullData.email;
      stateCopy.poste = fullData.poste;
      stateCopy.photo = fullData.photo;

      this.setState(stateCopy);
    });
  }

  removeSpaces = number => {
    console.log(number);

    const finalNumber = number.split(" ").join("");

    return finalNumber;
  };

  render() {
    const blueColor = "#4b5264";
    const redColor = "#e26d5a";

    return (
      <>
        <style>{`\
      body{\
        margin:0;\
        padding:0;\
    }\

    html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}


audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}


audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

button,
input {
  line-height: normal;
  }

button,
select {
  text-transform: none;
}

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

    img {\
        border:0 none;\
        height:auto;\
        line-height:100%;\
        outline:none;\
        text-decoration:none;\
    }\

    a img{\
        border:0 none;\
    }\

    .imageFix{\
        display:block;\
    }\

    table, td{\
        border-collapse:collapse;\
    }\

    #bodyTable{\
        height:100% !important;\
        margin:0;\
        padding:0;\
        width:100% !important;\
    }
    strong {
      font-weight: bold;
    }

    .img-email, .img-container {
      width: 150px;
    }

    .text {
      font-family: Arial, sans-serif;\
      color: ${blueColor};\
      font-size: 12px;
      line-height: 14px;
      text-decoration: none;
    }

    .number-list {
      font-family: Arial, sans-serif;\
      color: ${blueColor};\
      text-decoration: none;\
      font-weight: bold;\
      font-size: 13px;
      line-height: 16px;
    }

    .separator {
      font-size: 1px;\
      line-height: 1px;\
      border: 0px;\
      padding: 0px;\
      width: 25px;\
    }

    .fullname {
      font-family: Arial, sans-serif;\
      font-size: 16px;
      color: ${blueColor};\
      font-weight: bold;\
      line-height: 1;\
    }

    .link {
      font-family: Arial, sans-serif;
      color: ${redColor};
      font-weight: bold;
      font-size: 13px;
    }

    .poste {
      font-family: Arial, sans-serif;\
      color: ${redColor};\
      font-weight: bold;\
      margin-bottom: 14px;
      line-height: 1;\
      font-size: 14px;
      margin: 7px 0 9px 0;
    }

    .email {
      display: block;
      font-family: Arial, sans-serif;\
      font-size: 12px;
      color: ${blueColor};\
      text-decoration: none;\
    }

    strong {
      font-family: Arial, sans-serif;
    }
    \
    `}</style>
        <table
          style={{
            width: "100%",
            maxWidth: "500px",
            margin: 0,
            paddingTop: "10px",
            marginBottom: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            display: "table"
          }}
          width="500"
        >
          <tbody>
            <tr>
              {this.state.photoType === "photo" ? (
                <>
                  <td
                    style={{ verticalAlign: "top" }}
                    width="150"
                    className="img-container"
                  >
                    <img
                      width="150"
                      className="img-email"
                      alt="Keck"
                      src={this.state.photo}
                    />
                  </td>
                  <td className="separator"></td>
                </>
              ) : this.state.photoType === "cloche" ? (
                <>
                  <td
                    style={{ verticalAlign: "top" }}
                    width="150"
                    className="img-container"
                  >
                    <img
                      width="150"
                      className="img-email"
                      alt="Keck"
                      src="https://www.keck-chimie.fr/wp-content/uploads/2020/02/cloche.png"
                    />
                  </td>
                  <td className="separator"></td>
                </>
              ) : null}
              {/* {this.state.photo.length > 1 ? (
                <>
                  <td
                    style={{ verticalAlign: "top" }}
                    width="150"
                    className="img-container"
                  >
                    <img
                      width="150"
                      className="img-email"
                      alt="Keck"
                      src={this.state.photo}
                    />
                  </td>
                  <td className="separator"></td>
                </>
              ) : (
                <>
                  <td
                    style={{ verticalAlign: "top" }}
                    width="150"
                    className="img-container"
                  >
                    <img
                      width="150"
                      className="img-email"
                      alt="Keck"
                      src="https://www.keck-chimie.fr/wp-content/uploads/2020/02/cloche.png"
                    />
                  </td>
                  <td className="separator"></td>
                </>
              )} */}
              <td style={{ verticalAlign: "top" }}>
                <h2 className="fullname">
                  {this.state.name}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.lastName}
                  </span>
                </h2>
                <p className="poste">{this.state.poste}</p>
                {this.state.email.length > 1 ? (
                  <a
                    href={`mailto:${this.state.email}`}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="email"
                    style={{ fontFamily: "Arial, sans-serif" }}
                  >
                    {this.state.email}
                  </a>
                ) : null}
                <br />
                <br />
                <p>
                  <img
                    src="https://www.keck-chimie.fr/wp-content/uploads/2020/02/pointer_map.png"
                    width="10"
                    alt="Pointer map"
                    style={{ display: "inline-block", marginRight: "8px" }}
                  />
                  <a
                    href="https://www.google.com/maps/place/Keck+Chimie/@48.8683106,7.4710836,17z/data=!3m1!4b1!4m5!3m4!1s0x47969ebba4693f63:0xd1397d11cab549fb!8m2!3d48.8683106!4d7.4732723"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text"
                  >
                    Keck Chimie
                  </a>
                </p>
                <p className="text">
                  Zone Industrielle - B.P. 6 <br />
                  67340 INGWILLER - FRANCE
                </p>
                <br />
                <ul>
                  {this.state.numbers.map((number, index) => {
                    return (
                      <li key={index} className="number-list">
                        {number.type}:{" "}
                        <a
                          href={`tel:${number.number}`}
                          className="number-list"
                        >
                          {number.number}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <br />
                <p>
                  <a
                    href="https://www.keck-chimie.fr"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                    className="link"
                  >
                    www.keck-chimie.fr
                  </a>
                </p>
                <br />
                <p className="text">
                  Certifié <strong>ISO 9001</strong> &{" "}
                  <strong>ISO 14001</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
