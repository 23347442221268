import React, { Component } from "react";
import db from "./base";
import logo from "./assets/images/email_logo.svg";
import { Link } from "react-router-dom";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mails: {}
    };
  }

  componentDidMount() {
    db.bindCollection("emails", {
      context: this,
      state: "mails",
      withIds: true,
      then(data) {
        this.setState(state => ({
          ...state,
          loading: false
        }));
      },
      onFailure(err) {
        throw new Error(err);
      }
    });
  }

  render() {
    return (
      <section className="block-home">
        <article className="block-home__logo-container">
          <img src={logo} alt="logo" />
        </article>
        <section className="block-home__block-signatures">
          {Object.keys(this.state.mails).map(mail => {
            const nom = this.state.mails[mail].name;
            const lastName = this.state.mails[mail].lastName;
            const id = this.state.mails[mail].id;
            const photo = this.state.mails[mail].photo;

            return (
              <div className="signature" key={mail}>
                <p>{nom + " " + lastName}</p>
                <div className="signature__links">
                  {photo.length > 1 ? (
                    <Link
                      className="links__link first"
                      to={{
                        pathname: `/signature/${id}/photo`
                      }}
                    >
                      Photo
                    </Link>
                  ) : null}
                  <Link
                    className="links__link"
                    to={{
                      pathname: `/signature/${id}/cloche`
                    }}
                  >
                    Cloche
                  </Link>
                </div>
              </div>
            );
          })}
        </section>
        <section className="block-access-admin">
          <Link to="/admin">Admin --></Link>
        </section>
      </section>
    );
  }
}
