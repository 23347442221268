import React, { Component } from "react";
import { base } from "../../base";
import db from "../../base";
import { Redirect } from "react-router-dom";
import AdminSignature from "./AdminSignature/AdminSignature";

/* TODO :
  1/ Récupérer le localstorage "isLoggedIn" et le stocker dans le state
  2/ Si le state "isLoggedIn" est false, Redirect sur la page Login
  3/ Si il est true, Render le component
*/

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mails: {},
      name: "",
      lastName: "",
      email: "",
      numbers: [],
      photo: "",
      adresse: "",
      poste: "",
      isLoggedIn: false
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    base
      .collection("emails")
      .add({
        name: this.state.name,
        lastName: this.state.lastName,
        email: this.state.email,
        numbers: this.state.numbers,
        poste: this.state.poste,
        adresse: this.state.adresse,
        photo: this.state.photo
      })
      .then(ref => {
        this.setState({
          name: "",
          lastName: "",
          email: "",
          numbers: [],
          poste: "",
          adresse: "",
          photo: ""
        });
      });
  };

  handleDelete = index => {
    base
      .collection("emails")
      .doc(index)
      .delete();
  };

  handleChangeInput = event => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value });
  };

  handleChangeNumber = (event, index) => {
    const value = event.target.value;
    const name = event.target.name;
    const state = [...this.state.numbers];

    state[index][name] = value;

    this.setState({ numbers: state });
  };

  handleAddNumber = event => {
    event.preventDefault();

    const state = [...this.state.numbers];

    state.push({ type: "", number: "" });

    this.setState({ numbers: state });
  };

  handleDeleteNumber = (event, index) => {
    event.preventDefault();

    const state = [...this.state.numbers];

    state.splice(index, 1);

    this.setState({ numbers: state });
  };

  componentWillMount() {
    const loggedIn = localStorage.getItem("isLoggedIn");

    if (loggedIn === "true") {
      this.setState({
        isLoggedIn: true
      });
    }
  }

  componentDidMount() {
    db.bindCollection("emails", {
      context: this,
      state: "mails",
      withIds: true,
      then(data) {
        this.setState(state => ({
          ...state,
          loading: false
        }));
      },
      onFailure(err) {
        throw new Error(err);
      }
    });
  }

  render() {
    return this.state.isLoggedIn ? (
      <section className="block-admin">
        <section className="admin__block-emails">
          <p className="block-admin__title">Liste des emails</p>
          {Object.keys(this.state.mails).map(mail => {
            const mailAlias = this.state.mails[mail];
            const fullName = mailAlias.name + " " + mailAlias.lastName;

            return (
              <AdminSignature
                key={mail}
                handleDelete={() => this.handleDelete(mailAlias.id)}
                name={fullName}
              />
            );
          })}
        </section>
        <form
          className="block-admin__add"
          onSubmit={event => this.handleSubmit(event)}
        >
          <div className="full">
            <label>
              <span>Nom</span>
              <input
                type="text"
                onChange={event => this.handleChangeInput(event)}
                value={this.state.lastName}
                name="lastName"
              />
            </label>
            <label>
              <span>Prénom</span>
              <input
                type="text"
                onChange={event => this.handleChangeInput(event)}
                value={this.state.name}
                name="name"
              />
            </label>
          </div>
          <div className="full">
            <label>
              <span>Email</span>
              <input
                type="text"
                onChange={event => this.handleChangeInput(event)}
                value={this.state.email}
                name="email"
              />
            </label>
            <label>
              <span>Numeros</span>
              {this.state.numbers.map((number, index) => (
                <div key={index}>
                  <input
                    type="text"
                    onChange={event => this.handleChangeNumber(event, index)}
                    value={number.type}
                    name="type"
                  />
                  <input
                    type="text"
                    onChange={event => this.handleChangeNumber(event, index)}
                    value={number.number}
                    name="number"
                  />
                  <button
                    className="btn-submit"
                    onClick={event => this.handleDeleteNumber(event, index)}
                  >
                    Enlever le numero
                  </button>
                </div>
              ))}
              <div
                className="btn-submit"
                onClick={event => this.handleAddNumber(event)}
              >
                Ajouter un numéro
              </div>
            </label>
          </div>
          <label>
            <span>Poste</span>
            <input
              type="texte"
              onChange={event => this.handleChangeInput(event)}
              value={this.state.poste}
              name="poste"
            />
          </label>
          <label>
            <span>Adresse</span>
            <textarea
              type="texte"
              onChange={event => this.handleChangeInput(event)}
              value={this.state.adresse}
              name="adresse"
            ></textarea>
          </label>
          <label>
            <span>Photo</span>
            <input
              type="url"
              onChange={event => this.handleChangeInput(event)}
              value={this.state.photo}
              name="photo"
            />
          </label>
          <button
            type="submit"
            className="btn-submit"
            onClick={event => this.handleSubmit(event)}
          >
            Ajouter
          </button>
        </form>
      </section>
    ) : (
      <Redirect
        to={{
          pathname: "/login"
        }}
      />
    );
  }
}

export default Admin;
