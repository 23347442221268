import firebase from "firebase/app";
import "firebase/firestore";
import Rebase from "re-base";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDLtfO0exTyPbm0DtZAdNVq5s5wVMCEqnE",
  authDomain: "mails-keck.firebaseapp.com",
  databaseURL: "https://mails-keck.firebaseio.com",
  projectId: "mails-keck"
});

export const base = firebaseApp.firestore();

const db = Rebase.createClass(base);

export default db;
